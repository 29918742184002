import React from 'react';

export const FasterVideo = () => (
  <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'absolute' }}>
    <div
      className="wistia_responsive_wrapper"
      style={{ height: '100vh', left: 0, position: 'fixed', top: '0', width: '100vw' }}>
      <div
        className="wistia_embed wistia_async_qo9bf0og3b branding=false wistiaLogo=false smallPlayButton=false settingsControl=false volumeControl=false fullscreenButton=false controlsVisibleOnLoad=false playbar=false fitStrategy=cover autoPlay=true muted=true"
        style={{ height: '100%', position: 'relative', width: '100%' }}>
        <div
          className="wistia_swatch"
          style={{
            height: '100vh',
            left: 0,
            opacity: 0,
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
            transition: 'opacity 200ms',
            width: '100vw',
          }}>
          <img
            // src="https://fast.wistia.com/embed/medias/w7uu9dz8nu/swatch"
            src="https://fast.wistia.com/embed/medias/qo9bf0og3b/swatch"
            style={{ filter: 'blur(5px)', height: '100%', objectFit: 'cover', width: '100%' }}
            alt=""
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  </div>
);
