import React, { forwardRef, useEffect } from 'react';
import { Container } from './One.styled';
import { FasterVideo } from './FasterVideo';
import { LastScene } from './Animations';

const One = ({ videoFinished, setVideoFinished, setVideoStarted, videoStarted }, ref) => {
  useEffect(() => {
    if (!videoFinished)
      window.wistiaInit = () => {
        const video = window.Wistia.api('qo9bf0og3b');
        video.bind('play', () => {
          setVideoStarted(true);
          document.querySelector('body').classList.toggle('logo-background');
        });
        video.bind('end', () => {
          setVideoFinished(true);
        });
      };
  }, [videoFinished, setVideoFinished, setVideoStarted, videoStarted]);
  if (!videoFinished)
    return (
      <Container ref={ref}>
        <FasterVideo />
        {/* {videoStarted && <Animations />} */}
      </Container>
    );
  return (
    <Container ref={ref}>
      <LastScene />
    </Container>
  );
};

export default forwardRef(One);
