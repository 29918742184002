import styled from 'styled-components';
import { Close } from 'components/icons';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  visibility: visible;
  position: relative;
  padding: 45px 90px;
  width: ${({ isMobile }) => (isMobile ? '90%' : '50%')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  opacity: 1;
`;

export const CloseButton = styled(Close)`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;

export const Heading = styled.h3`
  color: #f75a40;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  width: 100%;
`;

export const IntroText = styled.p`
  font-size: 13px;
  color: #4d4e58;
  line-height: 1.2;
`;

export const InputFieldsWrapper = styled.div`
  width: 100%;
`;

export const InputField = styled.input.attrs({ type: 'text' })`
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid grey;
  width: 100%;
  padding: 10px 5px;
  :focus {
    border: 1px solid black;
    border-radius: 5px;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: inline-block !important;
  margin: 0 5px 0 0;
`;

export const Label = styled.label.attrs({ htmlFor: 'termsAndConditions' })`
  font-size: 13px;
  color: #4d4e58;
  line-height: 1.2;
`;

export const Submit = styled.button.attrs({ type: 'button' })`
  margin-top: 25px;
  align-self: center;
  color: white;
  border: 1px solid #f75a40;
  background: #f75a40;
  padding: 10px 40px;
  text-transform: uppercase;
  cursor: pointer;

  :disabled {
    background: transparent;
    border: 1px solid #4d4e58;
    color: #4d4e58;
    cursor: default;
  }
`;
