import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import gsap, { CSSPlugin } from 'gsap';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// @TODO: ?
import { Apartments } from 'pages/Apartments/Apartments';
import Header from 'containers/Header/Header';

import { ROUTES } from 'routes/constants';
import FloatingContact from 'components/FloatingContact/FloatingContact';
import HomePage from './pages/Home/Home';

import { Main } from './App.styled';

gsap.registerPlugin(CSSPlugin);

const Advantages = React.lazy(() => import('pages/Advantages/Advantages'));
const Contact = React.lazy(() => import('pages/Contact/Contact'));
const Location = React.lazy(() => import('pages/Location/Location'));
const Description = React.lazy(() => import('pages/Description/Description'));
const About = React.lazy(() => import('pages/About/About'));
const ThankYou = React.lazy(() => import('pages/ThankYou/ThankYou'));
const tagManagerArgs = {
  gtmId: 'GTM-KCHRJ2RQ',
};

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [videoFinished, setVideoFinished] = useState(false);
  const [isThankYouHandled, setIsThankYouHandled] = useState(false);
  const [thankYouError, setThankYouError] = useState(false);

  // GTM initialization
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const handleLeaveContactInfo = values => {
    const params = Object.keys(values)
      .map(key => `${key}=${values[key]}`)
      .join('&');

    setIsModalOpen(false);
    window.location.href = `${ROUTES.THANK_YOU}?${params}`;
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleThankYouPage = values => {
    fetch(process.env.REACT_APP_PIPEDREAM_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(res => {
        setThankYouError(res.status !== 200);
      })
      .catch(() => {
        setThankYouError(true);
      })
      .finally(() => {
        setIsThankYouHandled(true);
      });
  };

  return (
    <Router>
      <Header
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleLeaveContactInfo={handleLeaveContactInfo}
      />

      {!videoStarted && <div className="loader" />}
      <Main>
        <Switch>
          <Route exact path={ROUTES.BASE}>
            <HomePage
              setVideoStarted={setVideoStarted}
              setVideoFinished={setVideoFinished}
              videoFinished={videoFinished}
              videoStarted={videoStarted}
            />
          </Route>
          <Route path={ROUTES.LOCATION}>
            <Location />
          </Route>
          <Route path={ROUTES.ADVANTAGES}>
            <Advantages />
          </Route>
          <Route path={ROUTES.CONTACT}>
            <Contact handleLeaveContactInfo={handleOpenModal} />
          </Route>
          <Route exact path={ROUTES.APARTMENTS}>
            <Apartments />
          </Route>
          <Route exact path={ROUTES.DESCRIPTION}>
            <Description />
          </Route>
          <Route exact path={ROUTES.ABOUT}>
            <About />
          </Route>
          <Route exact path={ROUTES.THANK_YOU}>
            <ThankYou hasError={thankYouError} isHandled={isThankYouHandled} handleThankYouPage={handleThankYouPage} />
          </Route>
        </Switch>
      </Main>
      <FloatingContact onClick={handleOpenModal} isModalEnabled={isModalOpen} />
    </Router>
  );
}

export default App;
