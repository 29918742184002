import React from 'react';
import { IconWithText } from 'pages/Description/IconWithText'; // Import IconWithText component
import { Container } from './FloatingContact.styled';

function FloatingContact() {
  const handleCall = () => {
    window.location.href = 'tel:+381603040575';
  };

  return (
    <Container onClick={handleCall}>
      <IconWithText icon="fa-phone" />
    </Container>
  );
}

export default FloatingContact;
