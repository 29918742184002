export const timelineMonths = [
  { key: 1, path: 'home.sectionFour.video', url: 'https://www.youtube.com/embed/lKeNZDC8o9Y' },
  // { key: 1, path: 'Februar', year: 2024 },
  // { key: 2, path: 'Jun', year: 2024 },
  // { key: 3, path: 'Oktobar', year: 2024 },
];

export const onHoverAnimation = { height: '80%', duration: 0.5 };

export const onMouseLeaveAnimation = { height: '100%', duration: 0.5 };
