import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: auto 1rem 1rem auto;
  z-index: 9999;
  cursor: ${({ isModalEnabled }) => (isModalEnabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isModalEnabled }) => (isModalEnabled ? '0.3' : '1')};

  & > div {
    border: 1px solid white;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #131313;
  }
  & i {
    color: white;
    font-size: 20px;
  }
`;
