import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Transition } from 'react-transition-group';

import {
  Wrapper,
  Background,
  Container,
  Heading,
  IntroText,
  InputFieldsWrapper,
  InputField,
  CheckBox,
  CheckBoxWrapper,
  Label,
  Submit,
  CloseButton,
} from './Modal.styled';

const Modal = ({ isVisible, onSubmit, onClose }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation();

  const [state, setState] = useState({ name: '', phone: '', message: '', termsAndConditions: false });

  const handleChange = e => setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

  const handleCheckBoxChange = e => setState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));

  return (
    <Transition in={isVisible} appear mountOnEnter unmountOnExit timeout={0}>
      <>
        <Background onClick={onClose} />
        <Wrapper>
          <Container isMobile={isMobile}>
            <CloseButton onClick={onClose} />
            <Heading>{t('header.modal.heading')}</Heading>
            <IntroText>{t('header.modal.introText')}</IntroText>
            <InputFieldsWrapper>
              <InputField
                onChange={handleChange}
                value={state.name}
                name="name"
                placeholder={t('header.modal.nameLabel')}
              />
              <InputField
                onChange={handleChange}
                value={state.phone}
                name="phone"
                placeholder={t('header.modal.phoneLabel')}
                required
              />
              <InputField
                onChange={handleChange}
                value={state.email}
                name="email"
                placeholder={t('header.modal.emailLabel')}
              />
              <InputField
                onChange={handleChange}
                value={state.message}
                name="message"
                placeholder={t('header.modal.messagelabel')}
              />
              <CheckBoxWrapper>
                <CheckBox
                  id="newsletter"
                  name="newsletter"
                  disabled={!state.email}
                  checked={state.newsletter}
                  onChange={handleCheckBoxChange}
                />
                <Label>{t('header.modal.newsletter')}</Label>
              </CheckBoxWrapper>
              <CheckBoxWrapper>
                <CheckBox
                  id="termsAndConditions"
                  name="termsAndConditions"
                  checked={state.termsAndConditions}
                  onChange={handleCheckBoxChange}
                />
                <Label>{t('header.modal.termsAndConditions')}</Label>
              </CheckBoxWrapper>
            </InputFieldsWrapper>
            <Submit disabled={!state.termsAndConditions || !state.phone} onClick={() => onSubmit(state)}>
              {t('header.modal.submit')}
            </Submit>
          </Container>
        </Wrapper>
      </>
    </Transition>
  );
};

export default Modal;
