import React, { useEffect, useRef, forwardRef, useState, useCallback } from 'react';
import gsap, { TweenLite } from 'gsap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Transition } from 'react-transition-group';
import ImageUrl from 'assets/images/sections/four/info-bg.jpg';
import Image2Url from 'assets/images/sections/four/dinamika-gradnje.png';
import Image3Url from 'assets/images/sections/four/dinamika.png';
import YoutubeSlider from 'components/ImageSlider/YoutubeSlider';

import Flicking from '@egjs/react-flicking';
import { Arrow } from 'components/icons';
import {
  Wrapper,
  Image,
  TimelineSlider,
  TimelineItem,
  Info,
  Description,
  ButtonWrapper,
  Button,
  BackArrow,
  WhiteSquare,
  AnimationSquaresWrapper,
  Square,
  Image2,
  Image3,
  Container,
} from './Four.styled';
import { timelineMonths, onHoverAnimation, onMouseLeaveAnimation } from './constants';

const animateSquare = element => {
  gsap.set(element.current, { scale: 0, autoAlpha: 1 });
  return gsap.to(element.current, {
    y: '-=50%',
    scale: 1.5,
    duration: 0.7,
    immediateRender: false,
  });
};

const animateElement = (element, animationProps) => () => TweenLite.to(element, animationProps);

const Four = ({ isVisible, currentSlide }, ref) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation();

  const [hoveredMonth, setHoveredMonth] = useState(false);

  const whiteSquareTimeline = useRef(gsap.timeline({ paused: true }));
  // const twoSquareTimeline = useRef(gsap.timeline({ paused: true }));

  const flick = useRef(null);
  const description = useRef(null);
  const info = useRef([]);
  const whiteSquare = useRef(null);
  const wrapper = useRef(null);
  const leftSquare = useRef(null);
  const rightSquare = useRef(null);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const toggleSlider = useCallback(() => setIsSliderOpen(prevState => !prevState), []);

  useEffect(() => {
    whiteSquareTimeline.current.add(animateSquare(whiteSquare), 0).reverse();
  }, []);

  useEffect(() => {
    if (isVisible) {
      gsap.set(wrapper.current, { overflow: 'hidden', delay: 0.7 });
    } else {
      gsap.set(wrapper.current, { overflow: 'visible' });
    }

    whiteSquareTimeline.current.reversed(!isVisible);
  }, [isVisible, currentSlide]);

  const handleMouseEnter = useCallback(event => {
    setHoveredMonth(+event.currentTarget.dataset.index);
  }, []);

  const handleMouseLeave = useCallback(() => setHoveredMonth(null), []);

  return (
    <div ref={wrapper}>
      <Wrapper ref={ref}>
        <Transition
          in={isVisible}
          mountOnEnter
          unmountOnExit
          onEnter={() => {
            TweenLite.to(leftSquare.current, { duration: 0.8, xPercent: '-=100', delay: 0.5 });
            TweenLite.to(rightSquare.current, { duration: 0.8, xPercent: '+=100', delay: 0.5 });
          }}
          timeout={1000}
          onExit={() => {
            TweenLite.to(leftSquare.current, { duration: 0.3, xPercent: 0 });
            TweenLite.to(rightSquare.current, { duration: 0.3, xPercent: 0 });
          }}>
          <AnimationSquaresWrapper>
            <Square isleft ref={leftSquare} />
            <Square ref={rightSquare} />
          </AnimationSquaresWrapper>
        </Transition>
        <WhiteSquare ref={whiteSquare} />
        <Image src={ImageUrl} />
        {window.innerWidth < window.innerHeight ? <Image3 src={Image3Url} /> : <Image2 src={Image2Url} />}

        <Container isMobile={isMobile}>
          <ButtonWrapper isMobile={isMobile} isleft>
            {/* <Button type="button" disabled={!isMobile} onClick={() => flick.current.prev()}>
              <BackArrow color="white" size={36} />
            </Button> */}
          </ButtonWrapper>
          <TimelineSlider isMobile={isMobile}>
            <Flicking
              overflow={false}
              zIndex={410}
              gap={0}
              defaultIndex={isMobile ? 0 : 1}
              autoResize
              collectStatistics={false}
              renderOnlyVisible
              ref={flick}>
              {timelineMonths.map(month => (
                <TimelineItem
                  isMobile={isMobile}
                  data-index={month.key}
                  key={month.key}
                  onClick={() => month.url && toggleSlider()}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>
                  <Info isMobile={isMobile} ref={el => (info.current[month.key] = el)}>
                    {t(month.path)}
                    <span>{month.year}</span>
                  </Info>
                  <Transition
                    in={hoveredMonth === month.key}
                    onEnter={animateElement(info.current[month.key], onHoverAnimation)}
                    onExit={animateElement(info.current[month.key], onMouseLeaveAnimation)}
                    timeout={1000}>
                    <Description ref={description}>{t('home.sectionFour.openImagePreview')}</Description>
                  </Transition>
                </TimelineItem>
              ))}
            </Flicking>
          </TimelineSlider>
          <ButtonWrapper isMobile={isMobile}>
            {/* <Button type="button" disabled={!isMobile} onClick={() => flick.current.next()}>
              <Arrow color="white" size={36} />
            </Button> */}
          </ButtonWrapper>
        </Container>
        {isSliderOpen && <YoutubeSlider onClose={toggleSlider} />}
      </Wrapper>
    </div>
  );
};

export default forwardRef(Four);
