export const ROUTES = {
  BASE: '/',
  APARTMENTS: '/stanovi',
  ADVANTAGES: '/prednosti',
  LOCATION: '/lokacija',
  DESCRIPTION: '/opis',
  ABOUT: '/o-nama',
  CONTACT: '/kontakt',
  THANK_YOU: '/hvala',
};
