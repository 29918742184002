import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.png';

const StyledLogo = styled.img`
  //width: auto;
  //height: 7vh;
  //margin-left: 15px;
  //margin-right: 15px;
  width: 50px;
  height: auto;
`;

export const Logo = () => <StyledLogo src={logo} alt="Logo" />;
