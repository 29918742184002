import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import gsap, { TweenLite } from 'gsap/gsap-core';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { Logo } from 'components/Logo';
import ContactIcon from 'assets/images/header/callIcon.png';
import ContactIconHover from 'assets/images/header/callIconHover.png';
// import Call from 'assets/images/header/call.png';
import Menu from 'containers/Menu/Menu';

import {
  NavBar,
  Links,
  Link,
  Hamburger,
  Contact,
  Button,
  Languages,
  Line,
  DropDownButton,
  DropDown,
  ContactWrapper,
} from './Header.styled';

import { links, barAnimation, languages, phoneNumber } from './constants';
import Modal from './Modal/Modal';

const Header = ({ isModalOpen, handleCloseModal, handleLeaveContactInfo }) => {
  // handleLeaveContactInfo
  const { t, i18n } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropDownAnimating, setIsDropDownAnimating] = useState(false);

  const menuTimeline = useRef(gsap.timeline({ paused: true }));

  const menuBars = useRef({});
  const dropdownRef = useRef(null);

  const toggleMenuTimeline = useCallback(() => {
    setIsMenuOpen(prevState => !prevState);
    menuTimeline.current.reversed(!menuTimeline.current.reversed());
  }, []);

  const togglePhoneClick = () => {
    window.location.href = phoneNumber;
  };

  const handleLangChange = useCallback(
    event => {
      const { lang } = event.target.dataset;

      setLanguage(lang);
      i18n.changeLanguage(lang);
      localStorage.setItem('lang', JSON.stringify(lang));
    },
    [i18n],
  );

  const handleWindowResize = useCallback(() => {
    if (!isMenuOpen) return;

    const currentWidth = window.innerWidth;

    if (currentWidth > 830) {
      toggleMenuTimeline();
    }
  }, [isMenuOpen, toggleMenuTimeline]);

  useEffect(() => {
    const savedLang = JSON.parse(localStorage.getItem('lang'));

    if (savedLang) {
      i18n.changeLanguage(savedLang);
      setLanguage(savedLang);
    }
  }, [i18n]);

  useEffect(() => {
    menuTimeline.current
      .to(menuBars.current.topBar, barAnimation.top)
      .to(menuBars.current.middleBar, barAnimation.middle, 0)
      .to(menuBars.current.bottomBar, barAnimation.bottom, 0)
      .reverse();
  }, []);

  const handleClickOuside = useCallback(
    event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    },
    [setIsDropdownOpen],
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('mousedown', handleClickOuside);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('mousedown', handleClickOuside);
    };
  }, [handleWindowResize, handleClickOuside]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleDropdown = () => {
    if (isDropDownAnimating) return;
    setIsDropDownAnimating(true);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropDownAnimation = {
    duration: 0.5,
    autoAlpha: 0,
    rotate: '90deg',
    scale: 0,
    yPercent: -20,
    transformOrigin: 'right top',
    onComplete: () => {
      setIsDropDownAnimating(false);
    },
  };

  return (
    <>
      <NavBar onTouchMove={event => event.stopPropagation()} onWheel={event => event.stopPropagation()}>
        <Hamburger onClick={toggleMenuTimeline}>
          <line x1="16" y1="17" x2="368" y2="17" ref={e => (menuBars.current.topBar = e)} />
          <line x1="16" y1="139.2" x2="368" y2="139.2" ref={e => (menuBars.current.middleBar = e)} />
          <line x1="16" y1="261" x2="368" y2="261" ref={e => (menuBars.current.bottomBar = e)} />
        </Hamburger>
        <Languages>
          {languages.map((lang, i) =>
            i === 0 ? (
              <Fragment key={lang}>
                <Button onClick={handleLangChange} data-lang={lang} isActive={language === lang}>
                  {lang}
                </Button>
                <Line />
              </Fragment>
            ) : (
              <Button key={lang} onClick={handleLangChange} data-lang={lang} isActive={language === lang}>
                {lang}
              </Button>
            ),
          )}
        </Languages>
        <Links>
          {Object.values(links).map(({ name, tKey, path, isLogo }) => (
            <Link key={name} exact to={path} activeClassName="active">
              {isLogo ? <Logo /> : t(`header.${tKey}`)}
            </Link>
          ))}
        </Links>

        <ContactWrapper>
          <Contact
            src={isHovered ? ContactIconHover : ContactIcon}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={toggleDropdown}
            onBlur={toggleDropdown}
          />
          <Transition
            in={isDropdownOpen}
            appear
            mountOnEnter
            unmountOnExit
            timeout={500}
            onEnter={() => {
              TweenLite.from(dropdownRef.current, dropDownAnimation);
            }}
            onExit={() => {
              TweenLite.to(dropdownRef.current, dropDownAnimation);
            }}>
            <DropDown ref={dropdownRef}>
              <DropDownButton size="18px" onClick={togglePhoneClick}>
                <a href="tel:+381603040575">+381 60 30 40 575</a>
              </DropDownButton>
              {/* <DropDownButton color="#f75a40" onClick={handleLeaveContactInfo}>
                <img src={Call} alt="" />
                {t("header.leaveContact")}
              </DropDownButton> */}
            </DropDown>
          </Transition>
        </ContactWrapper>
        <Menu isVisible={isMenuOpen} toggleMenuTimeline={toggleMenuTimeline} />
      </NavBar>
      <Modal isVisible={isModalOpen} onSubmit={handleLeaveContactInfo} onClose={handleCloseModal} />
    </>
  );
};

export default Header;
