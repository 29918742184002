import React, { useEffect, useRef } from 'react';
import gsap, { TweenLite, TimelineLite } from 'gsap/gsap-core';
import { TextPlugin } from 'gsap/src/TextPlugin';
import BackGroundImage from 'assets/images/sections/one/header_image.jpg';
import { Image, ContentWrapper } from './One.styled';

gsap.registerPlugin(TextPlugin);

export const Animations = () => {
  const firstTextRef = useRef(null);
  const secondTextRef = useRef(null);
  const thirdTextRef = useRef(null);
  const whiteBoxRef = useRef(null);
  const darkSquareRef = useRef(null);
  const lightSquareRef = useRef(null);
  const firstContainerRef = useRef(null);
  const biggerTextRef = useRef(null);
  const smallerTextRef = useRef(null);
  const thirdGroupWrapperRef = useRef(null);
  const titleRef = useRef(null);
  const lineRef = useRef(null);
  useEffect(() => {
    TweenLite.fromTo(
      whiteBoxRef.current,
      0.5,
      { opacity: 0, height: 0, width: 0 },
      {
        height: 500,
        width: 500,
        delay: 1,
        opacity: 1,
        visibility: 'visible',
      },
    );
    TweenLite.fromTo(
      darkSquareRef.current,
      { opacity: 0, height: 100 },
      {
        delay: 1.5,
        height: 300,
        opacity: 0.7,
        visibility: 'visible',
      },
    );
    TweenLite.fromTo(
      lightSquareRef.current,

      { opacity: 0 },
      {
        delay: 2,
        opacity: 0.7,
        visibility: 'visible',
      },
    );
    TweenLite.to(firstTextRef.current, {
      visibility: 'visible',
      duration: 1,
      text: 'HOLLYWOOD',
      delay: 2.5,
    });
    TweenLite.to(secondTextRef.current, {
      duration: 0.5,
      text: 'HILL',
      delay: 3,
      visibility: 'visible',
    });
    TweenLite.to(thirdTextRef.current, {
      duration: 1.5,
      text: 'residences',
      delay: 3,
      visibility: 'visible',
    });
    TweenLite.to(darkSquareRef.current, {
      delay: 3.1,
      x: -280,
    });
    TweenLite.to(lightSquareRef.current, {
      delay: 3.1,
      x: 280,
    });
    TweenLite.to(darkSquareRef.current, {
      delay: 3.9,
      y: 100,
    });
    TweenLite.to(lightSquareRef.current, {
      delay: 3.9,
      y: -120,
    });
    TweenLite.to(darkSquareRef.current, {
      delay: 5.2,
      ease: 'strong.inOut',
      x: 280,
    });
    TweenLite.to(lightSquareRef.current, {
      delay: 5.2,
      ease: 'strong.inOut',
      x: -280,
    });
    TweenLite.to(darkSquareRef.current, {
      delay: 6.2,
      ease: 'strong.inOut',
      x: -50,
      y: -200,
    });
    TweenLite.to(lightSquareRef.current, {
      delay: 6.2,
      ease: 'strong.inOut',
      y: 250,
      x: 40,
    });
    TweenLite.to(firstContainerRef.current, {
      delay: 6.5,
      ease: 'strong.inOut',
      opacity: 0,
    });
    TweenLite.to(smallerTextRef.current, {
      duration: 2.5,
      y: 0,
      delay: 8,
      visibility: 'visible',
    });
    TweenLite.to(biggerTextRef.current, {
      duration: 2.5,
      width: 1000,
      delay: 8,
    });

    TweenLite.to(thirdGroupWrapperRef.current, {
      autoAlpha: 1,
      delay: 16,
    });

    TweenLite.to(thirdGroupWrapperRef.current, {
      duration: 1.5,
      width: '100%',
      delay: 16,
    });

    TweenLite.from(lineRef.current, {
      autoAlpha: 0,
      duration: 0.5,
      yPercent: '+=100',
      delay: 17,
    });

    TweenLite.from(titleRef.current, {
      duration: 0.5,
      xPercent: '+=100',
      delay: 17.5,
    });

    TweenLite.from('#text', {
      duration: 0.5,
      xPercent: '-=100',
      delay: 17.5,
    });

    const tl = new TimelineLite({ repeat: -1 });
    tl.to(lineRef.current, { duration: 2, backgroundColor: '#EDCEB9' });
    tl.to(lineRef.current, { duration: 2, backgroundColor: '#33474F' });
    tl.to(lineRef.current, { duration: 2, backgroundColor: '#ABD9EF' });
  }, []);

  return (
    <>
      {/* <StyledText ref={firstContainerRef}> */}
      {/* <h1 ref={firstTextRef}> </h1> */}
      {/* <h1 ref={secondTextRef}> </h1> */}
      {/* <h1 ref={thirdTextRef}> </h1> */}
      {/* <div ref={darkSquareRef} className="dark-square" /> */}
      {/* <div ref={lightSquareRef} className="light-square" /> */}
      {/* <div ref={whiteBoxRef} className="white-border" /> */}
      {/* </StyledText> */}
      {/* <SecondAnimationGroup> */}
      {/* <div ref={biggerTextRef}> */}
      {/* <h1>HOLLYWOOD HILL</h1> */}
      {/* </div> */}
      {/* <h3 ref={smallerTextRef}>luxury lifestyle residences</h3> */}
      {/* </SecondAnimationGroup> */}
    </>
  );
};

export const LastScene = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite({ repeat: -1 });
    tl.to(lineRef.current, { duration: 2, backgroundColor: '#EDCEB9' });
    tl.to(lineRef.current, { duration: 2, backgroundColor: '#33474F' });
    tl.to(lineRef.current, { duration: 2, backgroundColor: '#ABD9EF' });
  });
  return (
    <>
      <Image src={BackGroundImage} alt="" />
      {/* <UnsecureCanvas /> */}

      <ContentWrapper>
        <iframe
          title="virtual-tour"
          className="ku-embed"
          frameBorder="0"
          allowFullScreen
          allow="xr-spatial-tracking; gyroscope; accelerometer"
          scrolling="no"
          src="https://kuula.co/share/collection/7cdyh?logo=-1&info=0&fs=1&vr=0&zoom=1&sd=1&gyro=0&autorotate=0.3&autop=20&thumbs=1&inst=0"
          height="100%"
          width="100%"
        />
      </ContentWrapper>
    </>
  );
};
